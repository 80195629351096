const ROOT_DOMAIN = 'https://macsm.org';
const MARKETING_PAGE_DOMAIN = 'https://marketing.macsm.org';

export const login = '/login';
export const signup = '/signup';
export const affiliatePartnerSignup = '/partner/signup';
export const registrationSuccess = '/registration-success';
export const affiliatePartnerRegistrationSuccess = '/partner-registration-success';
export const forgotPassword = '/forgot-password';
export const verifyEmail = '/confirmation';
export const unauthorized = '/unauthorized';
export const notFound = '*';
export const invitedBuyerSetPassword = '/complete_sign_up';
export const resetPassword = '/reset-password/:token';
export const resetPasswordSuccess = '/reset-password-success';
export const resetPasswordEmailCheck = '/reset-password-check-email';

export const landingPage = `${ROOT_DOMAIN}/`;
export const home = `${ROOT_DOMAIN}/home`;
export const aboutUs = `${ROOT_DOMAIN}/about`;
export const wholeSaleMarketPlace = `/wholesale-market`;
export const services = `${ROOT_DOMAIN}/services`;
export const support = `${ROOT_DOMAIN}/support`;
export const aggregateSalesStatistics = `${ROOT_DOMAIN}/aggregate-sales-statistics`;
export const contactUs = `${ROOT_DOMAIN}/contact`;
export const affiliatePartnerStaticPage = `${ROOT_DOMAIN}/affiliate-partner`;
export const cookies = `${ROOT_DOMAIN}/cookies`;
export const jurisdiction = `${ROOT_DOMAIN}/jurisdiction`;
export const privacy = `${ROOT_DOMAIN}/privacy`;
export const terms = `${ROOT_DOMAIN}/terms`;
export const retailsPage = `${MARKETING_PAGE_DOMAIN}/shop-macadamias/`;
export const marvelousMacs = `${MARKETING_PAGE_DOMAIN}/marvelous-macadamias/`;
export const newsPage = `${MARKETING_PAGE_DOMAIN}/news-with-macadamias/`;

export const seller = {
  portal: '/seller/profile',
  dashboard: '/seller',
  listings: '/seller/listings',
  listingDetails: '/seller/listings/:id',
  offerDetails: '/seller/offers/:id',
  createProduct: '/seller/create-listing/product-details',
  updateProduct: '/seller/create-listing/:productId/product-details',
  reviewListing: '/seller/create-listing/:productId/review-listing',
  foodSafety: '/seller/create-listing/:productId/food-safety',
  declaration: '/seller/create-listing/:productId/declaration',
  offers: '/seller/offers',
  contracts: '/seller/contracts',
  contractDetails: '/seller/contracts/:id',
  bids: '/seller/bids',
};

export const buyer = {
  portal: '/buyer/profile',
  dashboard: '/buyer',
  listings: '/buyer/listings',
  listingDetails: '/buyer/listings/:id',
  orderDetails: '/buyer/orders/:id',
  createProduct: '/buyer/create-listing/product-details',
  updateProduct: '/buyer/create-listing/:productId/product-details',
  reviewListing: '/buyer/create-listing/:productId/review-listing',
  declaration: '/buyer/create-listing/:productId/declaration',
  orders: '/buyer/orders',
  contracts: '/buyer/contracts',
  contractDetails: '/buyer/contracts/:id',
  bids: '/buyer/bids',
};

export const admin = {
  dashboard: '/admin',
  allUser: '/admin/users',
  userDetails: '/admin/users/:id',
  userProfile: (userId) => `/admin/users/${userId}`,
  profile: '/admin/profile',
  productListings: '/admin/listing',
  affiliatePartners: '/admin/partners',
  affiliatePartnerDetails: '/admin/partners/:id',
  affiliatePartnerProfile: (userId) => `/admin/partners/${userId}`,
  contracts: '/admin/contracts',
  contractDetails: (id) => `/admin/contracts/${id}`,
  contractDetail: '/admin/contracts/:id',
  offerOderDetails: (id) => `/admin/listing/${id}`,
  adminOfferOderDetail: '/admin/listing/:id',
};

export const subscriptionMember = {
  portal: '/subscription_member/profile',
  dashboard: '/subscription_member',
  listings: '/subscription_member/listings',
  listingDetails: '/subscription_member/listings/:id',
  orderDetails: '/subscription_member/orders/:id',
  contractDetails: '/subscription_member/contracts/:id',
};

export const sharedRoutes = {
  navigateCreateProduct: (userRole) => `/${userRole}/create-listing/product-details`,
  navigateListingsDetails: (userRole, listingId) => `/${userRole}/listings/${listingId}`,
};

export const affiliatePartner = {
  portal: '/partner/portal',
  profile: '/partner/profile',
  inviteBuyer: '/partner/invite-buyer',
  buyerInvitationSent: '/partner/buyer-invitation-sent',
  invitationSuccess: '/invitation-success',
};
